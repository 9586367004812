class CustomSDKIntegration {
    
    constructor() {
   
    }
  
    ShowSDKContainers() {
      // if (document.getElementsByClassName('container-tryon-media')[0].style.display.toLowerCase() == "inline")
      //   return;
  
      if (window.outerWidth <= 1080) {
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      }
  
      document.getElementsByClassName('tryon-media-menu')[0].style.display = "inline";
      document.getElementsByClassName('container-tryon-media')[0].style.display = "inline";
      document.getElementById('products-media').style.display = "none";
      // document.getElementsByClassName('tryon-media-content')[0].style.display = "none";
    }
  
    IConsent(ev) {
      let isChecked = document.getElementById('chkIConsent').checked;
      let arrTryOnMenu = document.getElementsByClassName('tryon-type-btn');
  
      for (var i = 0; i < arrTryOnMenu.length; i++) {
        if (isChecked) {
          // arrTryOnMenu[i].style.display = "inline";
          arrTryOnMenu[i].classList.remove('disabledLabel');
          arrTryOnMenu[i].classList.add('unabledLabel');
        } else {
          // arrTryOnMenu[i].style.display = "none";
          arrTryOnMenu[i].classList.remove('unabledLabel');
          arrTryOnMenu[i].classList.add('disabledLabel');
        }
      }
    }
  
    
    GoBack() {
  
      document.getElementsByClassName('tryon-media-menu')[0].style.display = "none";
      document.getElementsByClassName('container-tryon-media')[0].style.display = "none";
      document.getElementById('products-media').style.display = "grid";
    }
  
  }
  
//  if (window.CustomSDKIntegration === undefined) {
    window.CustomSDKIntegration = new CustomSDKIntegration();
//  }
  
  
  // (function () {
  //     window.CustomSDKIntegration.EnableTryOn();
  
  
  // })();